<template>
  <div>
    <AppBreadcrumbs :breadcrumbs="breadcrumbs" />
    <div v-if="!loading" class="up-lesson">
      <h1 class="up-main-title up-lesson__title">
        <span>{{ isLecture ? "Лекция" : "Тест" }}</span>
        {{ lessonsData.name }}
      </h1>
      <Lecture v-if="isLecture" :lecture-data="lessonsData" />
      <template v-else>
        <AnswersToTheTest
          v-if="testBeDone"
          :answers="correctAnswers"
          :is-passed="testPassed"
          @back="backHandler"
          @re-pass="rePassHandler"
        />
        <Test
          v-else
          :questions="lessonsData.questions"
          :min-score="lessonsData.minScore"
          :key="currentLessonIdx"
          @test-finish="testFinishHandler"
          @back="backHandler"
        />
      </template>
      <div v-if="isLecture || testPassed" class="up-lesson__buttons">
        <el-button
          v-if="currentLessonIdx > 0"
          icon="el-icon-arrow-left"
          @click="backHandler"
        >
          К предыдущему уроку
        </el-button>
        <el-button v-if="isLastLesson" type="primary" @click="finishHandler">
          Завершить курс
        </el-button>
        <el-button v-else type="primary" @click="proceedHandler">
          К следующему уроку <i class="el-icon-arrow-right" />
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import Lecture from "@/components/listener/course/Lecture";
import Test from "@/components/listener/course/Test";
import AppBreadcrumbs from "@/components/AppBreadcrumbs";
import AnswersToTheTest from "@/components/listener/course/AnswersToTheTest";

export default {
  name: "Lessons",
  components: { Lecture, Test, AppBreadcrumbs, AnswersToTheTest },
  data: () => ({
    isLecture: true,
    lessonsData: {},
    loading: true,
    currentLessonIdx: 0,
    lessons: [],
    testPassed: false,
    testBeDone: false,
    correctAnswers: [],
  }),
  computed: {
    isLastLesson() {
      return this.currentLessonIdx === this.lessons.length - 1;
    },
    currentLesson() {
      return this.lessons.length > 0 ? this.lessons[this.currentLessonIdx] : {};
    },
    breadcrumbs() {
      return [
        {
          text: "Все курсы",
          path: "/listener",
        },
        {
          text: "Просмотр информации курса",
          path: `/listener/course/${this.$route.params.id}`,
        },
        {
          text: this.lessonsData.name,
        },
      ];
    },
  },
  methods: {
    setDefaultData() {
      this.lessons = JSON.parse(localStorage.getItem("currentLessons"));

      this.currentLessonIdx = this.lessons.findIndex(
        (l) => l.id === this.$route.params.lid
      );

      this.testPassed = false;
      this.testBeDone = false;
    },
    async startLesson() {
      try {
        await this.$axios.post(
          `/listener/flow/${this.$route.params.id}/step/${this.$route.params.lid}/touch`
        );
      } catch (e) {
        //
      }
    },
    async fetchTestResults() {
      const id = this.$route.params.id;
      const lid = this.$route.params.lid;

      try {
        const { data: testData } = await this.$axios.get(
          `/listener/flow/${id}/step/${lid}/testresult`
        );

        this.correctAnswers = testData.items;
        this.testPassed = this.lessonsData.minScore <= testData.scores * 100;
        this.testBeDone = true;
      } catch (e) {
        //
      }
    },
    testFinishHandler(testData) {
      this.testPassed = this.lessonsData.minScore <= testData.scores * 100;
      this.correctAnswers = testData.items;
      this.testBeDone = true;
    },
    async rePassHandler() {
      await this.startLesson();
      this.testBeDone = false;
    },
    async fetchLessonInfo() {
      const id = this.$route.params.id;
      const lid = this.$route.params.lid;

      try {
        const { data } = await this.$axios.get(
          `/listener/flow/${id}/step/${lid}`
        );

        this.lessonsData = data;
        this.isLecture = this.lessonsData.type === "lecture";

        this.setDefaultData();
        this.startLesson();
        this.scrollToTop();

        if (this.isLecture) return;

        if (
          this.currentLesson.state === "done" ||
          this.currentLesson.state === "failed"
        ) {
          this.fetchTestResults();
        }
      } catch (e) {
        //
      } finally {
        this.loading = false;
      }
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    backHandler() {
      this.$router.push({
        params: {
          lid: this.lessons[this.currentLessonIdx - 1].id,
        },
      });
    },
    proceedHandler() {
      this.$router.push({
        params: {
          lid: this.lessons[this.currentLessonIdx + 1].id,
        },
      });
    },
    async finishHandler() {
      try {
        const { data: res } = await this.$axios.post(
          `/listener/flow/${this.$route.params.id}/finish`
        );

        this.$router.push(`/listener/course/${res.id}/success`);
      } catch (e) {
        //
      }
    },
  },
  watch: {
    "$route.params.lid"() {
      this.fetchLessonInfo();
    },
  },
  created() {
    this.fetchLessonInfo();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/pages/listener/course/lessons.scss";
</style>