<template>
  <div v-if="currentQuestion" class="up-test">
    <p class="up-test__text">
      <span>
        Вопрос {{ currentQuestionIdx + 1 }} из {{ questions.length }}:
      </span>
      {{ currentQuestion.description }}
    </p>
    <div class="up-test__questions">
      <el-radio
        v-for="(q, i) in currentQuestion.answers"
        v-model="answerRadio"
        :label="i"
        border
        :key="`answer${i}`"
      >
        {{ q.text }}
      </el-radio>
    </div>
    <el-button @click="answerHandler" type="primary" v-loading="loading">
      Ответить
    </el-button>
  </div>
</template>

<script>
import showErrorMessage from "@/mixins/showErrorMessage.mixin";

export default {
  name: "Test",
  mixins: [showErrorMessage],
  props: {
    questions: Array,
    minScore: Number,
  },
  data: () => ({
    loading: false,
    answerRadio: 0,
    currentQuestionIdx: 0,
    answersOnQuestions: [],
  }),
  computed: {
    currentQuestion() {
      return this.questions[this.currentQuestionIdx];
    },
    isLastQuestion() {
      return this.currentQuestionIdx === this.questions.length;
    },
  },
  methods: {
    collectAnswers() {
      const userAnswer = this.currentQuestion.answers[this.answerRadio];

      this.answersOnQuestions.push({
        id: this.currentQuestion.id,
        answerId: userAnswer.id,
      });
    },
    answerHandler() {
      this.collectAnswers();

      this.answerRadio = 0;
      this.currentQuestionIdx++;

      if (!this.isLastQuestion) return;

      this.sendTest();
    },
    async sendTest() {
      if (this.loading) return;

      this.loading = true;

      const id = this.$route.params.id;
      const lid = this.$route.params.lid;

      try {
        const { data } = await this.$axios.post(
          `/listener/flow/${id}/step/${lid}/sendtest`,
          this.answersOnQuestions
        );

        this.$emit("test-finish", data);
      } catch (e) {
        this.showErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/listener/course/test.scss";
</style>