<template>
  <div class="up-answers">
    <p class="up-answers__text" :class="{ 'is-error': !isPassed }">
      Тест <template v-if="!isPassed">не</template> пройден. Верных ответов:
      {{ countOfCorrectAnswers }} из {{ answers.length }} ({{
        percantageOfCorrectAnswers
      }}%)
    </p>
    <div
      v-for="(a, i) in answers"
      :key="i"
      class="up-answers__question up-question"
    >
      <span class="up-question__counter">
        Вопрос: {{ i + 1 }} из {{ answers.length }}
      </span>
      <p class="up-question__name">{{ a.question.description }}</p>
      <p v-if="a.correct" class="up-question__answer is-success">
        {{ a.answer.text }} <i class="el-icon-circle-check" />
      </p>
      <template v-else>
        <p class="up-question__answer is-error">
          {{ a.answer.text }} <i class="el-icon-circle-close" />
        </p>
        <p class="up-question__answer is-success">
          {{ a.rightAnswer.text }} <i class="el-icon-circle-check" />
        </p>
      </template>
    </div>
    <div v-if="!isPassed" class="up-answers__buttons">
      <el-button icon="el-icon-arrow-left" @click="$emit('back')">
        К предыдущему уроку
      </el-button>
      <el-button type="danger" @click="$emit('re-pass')">
        Пройти повторно
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnswersToTheTest",
  props: {
    answers: Array,
    isPassed: Boolean,
  },
  computed: {
    countOfCorrectAnswers() {
      return this.answers.reduce((acc, curr) => {
        return curr.correct ? acc + 1 : acc;
      }, 0);
    },
    percantageOfCorrectAnswers() {
      return Math.round(
        (this.countOfCorrectAnswers / this.answers.length) * 100
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/listener/course/answers-to-the-test.scss";
</style>